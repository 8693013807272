import React from "react";
import { Link } from "gatsby";
import styled, { css } from "styled-components";

import Layout from "../layout";
import SEO from "../seo";
import media from "../../utils/mediaQuery";
import defaultTheme from "../../utils/defaultTheme";
import Container from "../Container";
import Stack from "../Stack";
import analyze from "../../images/il-analyze2.svg";

const StyledContentWrapper = styled.h3`
  font-size: 1.125rem;
  color: rgba(5, 5, 38, 0.8);
`;

const StyledCard = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 100%;
  padding: 30px 30px 45px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: 200ms;
  position: relative;
  cursor: pointer;

  * {
    text-decoration: none;
  }

  &:hover {
    box-shadow: 0 7px 14px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
  }
`;
const StyledGrid = styled.div`
  display: grid;
  grid-gap: calc(1rem + 0.5vw);
  grid-template-columns: repeat(1, 1fr);

  ${media.desktop(css`
    grid-template-columns: repeat(3, 1fr);
  `)}
`;

StyledGrid.defaultProps = {
  theme: defaultTheme
};

const StyledTimeToRead = styled.div`
  opacity: 0.8;
  font-size: 16px;
  position: absolute;
  bottom: 15px;
`;

const Ilustration = styled.div``;

const StyledImage = styled.img`
  margin-bottom: 0;
`;

const StyledStack = styled(Stack)``;

const Section = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
`;

const StyledText = styled.p`
  font-size: 16px;

  word-break: break-all;

  ${media.desktop(css`
    word-break: normal;
  `)}
`;

StyledText.defaultProps = {
  theme: defaultTheme
};

const Card = ({ node }) => {
  return (
    <StyledLink to={node.fields.slug}>
      <StyledCard key={node.id}>
        <Stack justify="between" spacing="xSmall" direction="column">
          <h4>{node.frontmatter.title}</h4>
          <StyledStack flex grow>
            <StyledText>{node.excerpt}</StyledText>
          </StyledStack>
          <StyledTimeToRead>Čtení na {node.timeToRead} min</StyledTimeToRead>
        </Stack>
      </StyledCard>
    </StyledLink>
  );
};

const StyledList = styled.div``;

const StyledListItem = styled.div`
  align-content: center;
  padding: 20px 0;
  border-bottom: 1px solid rgba(5, 5, 38, 0.1);
  transition: 300ms;

  ${media.desktop(css`
    display: flex;
  `)}

  &:hover {
    cursor: pointer;
    opacity: 0.8;
    transform: translateX(10px);
  }
`;
StyledListItem.defaultProps = {
  theme: defaultTheme
};

const StyledListHeading = styled.h4`
  font-weight: 400;
  font-size: 1rem;
  margin: 0;
  min-width: 280px;
  line-height: 2;
  text-decoration: none;
`;

const StyledTextItem = styled.p`
  font-weight: 400;
  font-size: 1rem;
  color: rgba(5, 5, 38, 0.6);
  margin: 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 2;
  text-decoration: none;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const ListItem = ({ node }) => {
  return (
    <StyledLink to={node.fields.slug}>
      <StyledListItem>
        <StyledListHeading>{node.frontmatter.title}</StyledListHeading>
        <StyledTextItem>{node.excerpt}</StyledTextItem>
      </StyledListItem>
    </StyledLink>
  );
};

const Category = ({ title, description, titleImage, data, type }) => {
  const dataToMap = data.allMarkdownRemark.edges
    .filter((value, index) => {
      return value.node.frontmatter.category === title;
    })
    .sort((a, b) => {
      return a.node.frontmatter.order - b.node.frontmatter.order;
    });

  return (
    <>
      {dataToMap.length >= 0 && (
        <Section>
          <Stack spacing="large">
            <StyledContentWrapper>{title}</StyledContentWrapper>
            {type === "list" ? (
              <StyledList>
                {dataToMap.map(({ node }) => {
                  if (type === "list") {
                    return <ListItem node={node} />;
                  }
                })}
              </StyledList>
            ) : (
              <StyledGrid
                numberOfPost={data.allMarkdownRemark.edges.length}
                perRow={2}
              >
                {dataToMap.map(({ node }) => {
                  return <Card node={node} />;
                })}
              </StyledGrid>
            )}
          </Stack>
        </Section>
      )}
    </>
  );
};

export default Category;
