import React from "react";
import { Link, graphql } from "gatsby";
import styled, { css } from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import media from "../utils/mediaQuery";
import defaultTheme from "../utils/defaultTheme";
import Container from "../components/Container";
import Stack from "../components/Stack";
import Category from "../components/Category";
import Hero from "../components/Hero";
import Cta from "../components/Cta";

//images
import analyze from "../images/il-analyze2.svg";
import firstSteps from "../images/il-firststeps.svg";
import comparison from "../images/il-comparison.svg";
import invoice from "../images/il-invoice.svg";
import translation from "../images/il-translation.svg";
import platba from "../images/il-platba.svg";

import { CATEGORIES } from "../consts.js";

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Hero></Hero>
      <Container>
        <SEO title="České shopify" keywords={[`shopify v česku`]} />
        <Category title={CATEGORIES.PROME} titleImage={analyze} data={data} />
        <Category
          title={CATEGORIES.PRVNIKRUCKY}
          titleImage={firstSteps}
          data={data}
          type="list"
        />
        <Category
          title={CATEGORIES.PREKLAD}
          titleImage={translation}
          data={data}
        />
        <Category
          title={CATEGORIES.PLATBA}
          titleImage={platba}
          data={data}
          type="list"
        />
        <Category
          title={CATEGORIES.FAKTURACE}
          titleImage={invoice}
          data={data}
        />
        <Category
          title={CATEGORIES.SROVNAVACE}
          titleImage={comparison}
          data={data}
          type="list"
        />
      </Container>
      <Cta></Cta>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark {
      totalCount
      edges {
        node {
          timeToRead
          id
          fields {
            slug
          }
          frontmatter {
            title
            category
            order
          }
          excerpt
        }
      }
    }
  }
`;

export default IndexPage;
