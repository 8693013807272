// @flow

import React from "react";
import styled, { css } from "styled-components";
import Stack from "../Stack";
import Header from "../header";
import Container from "../Container";
import defaultTheme from "../../utils/defaultTheme";
import heroImg from "../../images/il-start.svg";
import media from "../../utils/mediaQuery";

const StyledHero = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  position: relative;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 350px;
  }

  &:after {
    z-index: 0;
    background-image: linear-gradient(
      132deg,
      #f64f59 0%,
      #c471ed 54%,
      #19e9f0 100%
    );
  }

  &:before {
    z-index: 1;
    background: rgba(114, 92, 196, 0.5);
  }
`;

const StyledIndexWrapper = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
`;

StyledHero.defaultProps = {
  theme: defaultTheme
};

const StyledImageWrapper = styled.div`
  display: none;

  ${media.tablet(css`
    display: block;
    width: 50%;
    height: 480px;
    background-image: ${({ imageSrc }) => `url(${imageSrc})`};
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  `)}
`;

StyledImageWrapper.defaultProps = {
  theme: defaultTheme
};

const StyledContentWrapper = styled.div`
  width: 100%;
  height: 480px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.tablet(css`
    width: 50%;
  `)}
`;

StyledContentWrapper.defaultProps = {
  theme: defaultTheme
};

const StyledLargeText = styled.p`
  font-size: 1.2rem;
`;

const StyledImage = styled.img`
  margin-bottom: 0;
`;

const StyledWhiteBox = styled.div`
  color: #fff;
  min-height: 350px;

  ${media.tablet(css`
    min-height: auto;
    margin-top: 16px;
  `)}
`;

StyledWhiteBox.defaultProps = {
  theme: defaultTheme
};
const Hero = ({}) => {
  return (
    <StyledHero>
      <StyledIndexWrapper>
        <Container>
          <Header></Header>
          <Stack justify="center" flex direction="row" align="center">
            <StyledContentWrapper>
              <StyledWhiteBox>
                <h1>Pro podnikavce na Shopify</h1>
                <StyledLargeText>
                  Zdroj informací pro všechny kteří si chtějí
                  <br />
                  otevřít svůj e-shop na Shopify.com.
                </StyledLargeText>
              </StyledWhiteBox>
              <div>
                <h2 id="zacatky">Seriál o Shopify</h2>
                <StyledLargeText>
                  Prozkoumejte začátky podnikání
                  <br />
                  na Shopify s našim seriálem
                </StyledLargeText>
              </div>
            </StyledContentWrapper>
            <StyledImageWrapper imageSrc={heroImg}></StyledImageWrapper>
          </Stack>
        </Container>
      </StyledIndexWrapper>
    </StyledHero>
  );
};

export default Hero;
